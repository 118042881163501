function ErrorComponent() {
  return (
    <div className="ErrorComponent">
      <h1>Jordan 🦁 is working really hard to get you outta here!</h1>
      <div>
        <p>
          Apologies for the 404. Reach out to Jordan 🦁 at igbobros@proton.me 🙋🏾‍♂️
        </p>
        <p>
          Try heading back <a href="https://www.jordy-rest-api.com">Home 📍</a>
        </p>
      </div>
    </div>
  );
}

export default ErrorComponent;
