import axios from "axios";

export const apiClient = axios.create({
  //   baseURL: "http://localhost:5000", //#CHANGE
  // baseURL:
  //   "http://rest-api-full-stack-mysql-env.eba-k3mxa5pj.us-west-1.elasticbeanstalk.com",
  baseURL: "https://jordy-rest-api-backend.com",
});

/* For Best Practices https://facebook.github.io/create-react-app/docs/adding-custom-environment-variables*/
