function LogoutComponent() {
  return (
    <div className="LogoutComponent">
      <h1>You are logged out!</h1>
      <div>
        <p>Thank you for using Jordan's 🦁 App. Come back soon!</p>
      </div>
    </div>
  );
}

export default LogoutComponent;
